import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import { Sidebar } from "../../../../components/Sidebar";
import {
  getBankDefaultInfos,
  getOfferInvoices,
  getOrderDetail,
  getPartialPayment,
} from "../../../../services/surplus-allocation";
import { OrderStateType } from "../../orderStateType";
import OrderInformationBox from "./OrderInformationBox";
import { makeStyles } from "@mui/styles";
import ProduceInformationBox from "./ProduceInformationBox";
import OrderDocumentsBox from "./OrderDocumentsBox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PartialPayment from "./PartialPayment";
import AdditionalCosts from "./AdditionalCosts";
import { InvoicesState } from "./InvoiceInterface";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Notes from "./Notes";
import BankAccounts from "./BankAccounts";
import { BankAccountsDefaultTypes } from "../../../CreateNewSurplus/components/BankInfo";
import dayjs from "dayjs";

const schema = yup.object().shape({
  feePercentagePartial:yup.string(),
  buyerRef: yup.string(),
  sellerRef: yup.string(),
  produces: yup.array().of(
    yup.object().shape({
      dealPriceIndication: yup.string(),
      priceGuide: yup.string(),
      quantity: yup.string(),
    })
  ),
  creditNoteProduces: yup.array().of(
    yup.object().shape({
      total: yup.string(),
      quantity: yup.string(),
    })
  ),
  capturePaymentAmount: yup.string(),
  discountType: yup.string(),
  discountTypeValue: yup.string(),
  feePercentage: yup.string(),
  upfrontPaymentAmount: yup.string(),
  finalPaymentAmount: yup.string(),
  markAsPaidDate:yup.string()
});

const useStyles = makeStyles({
  produceGrid: {
    width: "100%",
    minHeight: "100%",
    backgroundColor: "#ffff",
    borderRadius: "20px",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    "& MuiTableContainer": {
      borderRadius: "20px!important",
    },
  },
  orderGrid: {
    minHeight: "100%",
    width: "100%",
    backgroundColor: "#ffff",
    borderRadius: "20px",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    "& MuiTableContainer": {
      borderRadius: "20px!important",
    },
  },
  partialGrid: {
    marginTop: 10,
    minHeight: "100%",
    width: "100%",
    backgroundColor: "#ffff",
    borderRadius: "20px",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    "& MuiTableContainer": {
      borderRadius: "20px!important",
    },
  },
  notesGrid: {
    marginTop: 10,
    minHeight: "100%",
    width: "100%",
    backgroundColor: "#ffff",
    borderRadius: "20px",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    "& MuiTableContainer": {
      borderRadius: "20px!important",
    },
  },
  documentsGrid: {
    padding: 10,
    backgroundColor: "#ffff",
    borderRadius: "20px",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    "& MuiTableContainer": {
      borderRadius: "20px!important",
    },
  },
});

export interface PartialState {
  offerPaymentCode: string;
  dueDate:string
  name: string;
  status: string;
  capturedBy: any;
  capturedAt: any;
  _id: string;
  offerId: string;
  amount: number;
  createdAt: string;
  updatedAt: string;
  id: string;
  payment_option:PaymentOption
}

export interface PaymentOption {
  amount_details: AmountDetails
  id: string
  term: string
  type: string
  amount: number
  currency: string
  due_date: string
}

export interface AmountDetails {
  original_amount: number
  platform_fee_amount: number
  buyer_fee_amount: number
  seller_fee_amount: number
  platform_fee_percentage: number
  seller_fee_percentage: number
  buyer_fee_percentage: number
  buyer_fee_is_default: boolean
  seller_fee_is_default: boolean
}


const SurplusOrderDetailContainer: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState<OrderStateType>();
  const [invoices, setInvoices] = useState<InvoicesState[]>([]);
  const [selectedBankDetailId, setSelectedBankDetailId] = useState("");
  const [partialPayment, setPartialPayment] = useState<PartialState[]>([]);
  const [bankInfos, setBankInfos] = useState<BankAccountsDefaultTypes>();
  const [bankInfosLoading, setBankInfosLoading] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);
  const [refreshPartial, setRefreshPartial] = useState<number>(0);

  const {
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      buyerRef: "",
      markAsPaidDate:dayjs(new Date() as any) as any,
      feePercentagePartial:"",
      feePercentage: "",
      upfrontPaymentAmount: "",
      sellerRef: "",
      finalPaymentAmount: "",
    },
    resolver: yupResolver(schema),
  });

  //bank account infos

  useEffect(() => {
    if (
      orderDetails?.sellerCompany._id &&
      orderDetails.buyerCompany._id &&
      orderDetails.produces[0].currency.name &&
      !bankInfos?.companyBankDetails
    ) {
      const getBankAccountInfos = async () => {
        setBankInfosLoading(true);
        const infos = await getBankDefaultInfos();

        if (infos?.status === 200) {
          setBankInfos(infos.data);
        }
        setBankInfosLoading(false);
      };
      getBankAccountInfos();
    }
  }, [orderDetails, bankInfos?.companyBankDetails]);

  useEffect(() => {
    if (orderDetails?._id) {
      setValue("buyerRef", orderDetails.refs.buyerReferenceNumber || "");
      setValue("sellerRef", orderDetails.refs.sellerReferenceNumber || "");
    }
  }, [orderDetails?._id]);

  useEffect(() => {
    const getDetails = async () => {
      setFetchLoading(true);
      const response = await getOrderDetail(id ? id : "");
      const invoices = await getOfferInvoices(id ? id : "");
      setFetchLoading(false);

      if (invoices) {
        setInvoices(invoices?.items);
      }

      if (response) {
        setOrderDetails(response);
      }
    };
    getDetails();
  }, [id, refresh]);

  useEffect(() => {
    if (id) {
      const getPartialPaymentData = async () => {
        setFetchLoading(true);
        const response = await getPartialPayment(id ? id : "");
        setFetchLoading(false);

        if (response) {
          setPartialPayment(response);
        }
      };
      getPartialPaymentData();
    }
  }, [id, refreshPartial]);

  return (
    <Sidebar pageTitle="Details">
      {loading && <LoadingIndicator loading />}
      <div className="container pb-3">
        <div className="row g-2">
          <div className={`col-md-8`}>
            {fetchLoading ? (
              <Skeleton count={1} style={{ height: 800, borderRadius: 20 }} />
            ) : (
              <div className={classes.produceGrid}>
                {" "}
                <ProduceInformationBox
                payments={partialPayment}
                  setOrderData={setOrderDetails}
                  invoices={invoices}
                  setRefreshPartial={setRefreshPartial}
                  setRefresh={setRefresh}
                  watch={watch}
                  errors={errors}
                  setValue={setValue}
                  control={control}
                  orderData={orderDetails}
                />
              </div>
            )}
          </div>
          <div className="col-md-4">
            {fetchLoading ? (
              <Skeleton count={1} style={{ height: 800, borderRadius: 20 }} />
            ) : (
              <div className={classes.orderGrid}>
                <OrderInformationBox
                  watch={watch}
                  control={control}
                  setInvoices={setInvoices}
                  setOrderData={setOrderDetails}
                  refresh={refresh}
                  setValue={setValue}
                  setRefreshPartial={setRefreshPartial}
                  partialPayment={partialPayment}
                  invoices={invoices}
                  setRefresh={setRefresh}
                  orderData={orderDetails}
                />
              </div>
            )}
          </div>
          <div className="container pb-3">
            <div className="row  g-2">
              <div className="col-md-12">
                {fetchLoading ? (
                  <Skeleton
                    count={1}
                    style={{ borderRadius: 20, height: 200 }}
                  />
                ) : (
                  <div className={classes.partialGrid}>
                    <AdditionalCosts
                      invoice={invoices[0]}
                      setRefreshPartial={setRefreshPartial}
                      setRefresh={setRefresh}
                      orderId={orderDetails?._id || ""}
                      currencySymbol={orderDetails?.produces[0].currency.symbol}
                      additionalCosts={orderDetails?.additionalCosts}
                    />
                  </div>
                )}
              </div>
              <div className="col-md-12">
                {fetchLoading ? (
                  <Skeleton
                    count={1}
                    style={{ borderRadius: 20, height: 200 }}
                  />
                ) : partialPayment.length ? (
                  <div className={classes.partialGrid}>
                    <PartialPayment
                    data={orderDetails}
                    setValue={setValue}
                      orderStatus={orderDetails?.status}
                      setRefreshPartial={setRefreshPartial}
                      setRefresh={setRefresh}
                      control={control}
                      watch={watch}
                      currencySymbol={orderDetails?.produces[0].currency.symbol}
                      partialPayment={partialPayment}
                    />
                  </div>
                ) : fetchLoading ? (
                  <Skeleton
                    count={1}
                    style={{ borderRadius: 20, height: 200 }}
                  />
                ) : (
                  <div className={classes.partialGrid}>
                    <OrderDocumentsBox orderData={orderDetails} />
                  </div>
                )}
              </div>{" "}
              <div className="col-md-6">
                {fetchLoading ? (
                  <Skeleton
                    style={{ borderRadius: 20, height: 200 }}
                    count={1}
                  />
                ) : (
                  <div className={classes.notesGrid}>
                    <BankAccounts
                      invoiceId={invoices?.[0]?._id}
                      defaultBankInfo={invoices?.[0]?.bankInformation}
                      selectedBankDetailId={selectedBankDetailId}
                      setSelectedBankDetailId={setSelectedBankDetailId}
                      bankInfos={bankInfos}
                      loading={bankInfosLoading}
                      setRefresh={setRefresh}
                      setOrderData={setOrderDetails}
                      orderData={orderDetails}
                    />
                  </div>
                )}
              </div>
              <div className="col-md-6">
                {fetchLoading ? (
                  <Skeleton
                    style={{ borderRadius: 20, height: 200 }}
                    count={1}
                  />
                ) : (
                  <div className={classes.notesGrid}>
                    <Notes
                      setRefresh={setRefresh}
                      setOrderData={setOrderDetails}
                      orderData={orderDetails}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {fetchLoading ? (
            <Skeleton count={1} style={{ borderRadius: 20, height: 200 }} />
          ) : partialPayment.length ? (
            <div className="col-md-12 mt-1">
              <div className={classes.documentsGrid}>
                <OrderDocumentsBox orderData={orderDetails} />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Sidebar>
  );
};

export default SurplusOrderDetailContainer;
