import React, { useState, useEffect } from "react";
import { TableRow } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { Divider } from "@mui/material";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import ControlledComboBox from "../../../components/ControlledComboBox";
import { DocumentsType } from "./interfaces";
const useStyles = makeStyles((theme) => ({
  hr: {
    opacity: 0.4,
  },
  mainText: {
    fontSize: 18,
  },
  Input: {
    borderRadius: "10px!important",
    width: "100%",
    height: 45,
    padding: 15,
  },
  checkLimitButton: {
    border: "none",
    borderRadius: 10,
    width: "100%",
    background: "#E77228",
    color: "white",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    height: "40px",
    marginTop: 12,
    "&:disabled": {
      opacity: 0.5,
      cursor: "default",
    },
  },
  customControlledField: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  checkSuccess: {
    color: "#69CDAE!important",
  },
  checkError: {
    color: "red!important",
  },
  chooseFile: {
    border: "none",
    borderRadius: 10,
    padding: 5,
    width: "100%",
    color: "white",
    background: "#E77228",
    marginTop: 17,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    height: "45px",

    "&:disabled": {
      opacity: 0.5,
      cursor: "default",
    },
  },
  addFile: {
    border: "none",
    borderRadius: 10,
    width: "100%",
    color: "white",
    background: "#69CDAE",
    marginTop: 17,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    height: "45px",

    "&:disabled": {
      opacity: 0.5,
      cursor: "default",
    },
  },
  fontFamily: {
    fontFamily: "Montserrat,sans-serif!important",
  },
  trash: {
    cursor: "pointer",
  },
  delete: {
    cursor: "pointer",
    fontSize: 20,
    color: "red",
  },
}));
const OrderDocuments = ({
  documents,
  setDocuments,
  selectedDocumentFile,
  setSelectedDocumentFile,
  control,
  setValue,
  watch,
  reset,
  errors,
  sellerCompany,
}: {
  setDocuments: React.Dispatch<React.SetStateAction<DocumentsType[]>>;
  documents: DocumentsType[];
  setSelectedDocumentFile: any;
  selectedDocumentFile: any;
  control: any;
  watch: any;
  reset: any;
  errors: any;
  setValue: any;
  sellerCompany?: { name: string; id: string };
}) => {
  const documentNames = [
    "Shipping Document",
    "Picker Notes",
    "Credit Notes",
    "QC(Quality Control) report",
    "Invoice",
    "Purchase Order",
    "Sales Report",
    "Certificate of Origin",
    "Pesticides Record",
    "LOI (Letter of Indemnity)",
    "Packing List",
    "Logistics Booking Confirmation",
    "Logistics Forward Cargo Receipt",
    "Logistics BOL",
    "Logistics Airway Bill",
    "Others",
  ];

  const classes = useStyles();
  const buyer = watch("buyerCompany");
  const currencies = watch("produceCurrency");
  const watchApplyInvoiceFactoring = watch("applyInvoiceFactoring");
  const documentType = watch("documentType");
  const [loading, setLoading] = useState(false);

  const onFileChange = (e: any) => {
    setSelectedDocumentFile(e.target.files[0]);
  };

  const onAddDocument = () => {
    setDocuments((prev) => [
      ...prev,
      {
        documentType: documentType,
        url: URL.createObjectURL(selectedDocumentFile),
        file: selectedDocumentFile,
      },
    ]);
    setValue("documentType", "");
    setSelectedDocumentFile(undefined);
  };

  const onDeleteDocument = (index: number) => {
    const newDocuments = documents?.filter((item, i) => i !== index);

    setDocuments(newDocuments);
  };

  return (
    <div className="mt-1">
      <span className={classes.mainText}>Order Documents</span>
      <Divider className={classes.hr} />
      {loading && <LoadingIndicator loading={loading} />}
      <div className="d-flex gap-4 pt-3 flex-row align-items-center">
        <div className="col-6">
          <ControlledComboBox
            className={classes.customControlledField}
            control={control}
            name="documentType"
            error={!!errors?.documentType}
            helperText={errors?.documentType?.message}
            options={documentNames}
            textLabel={"Document Type"}
          />
        </div>

        <div className="col-3">
          <label style={{ width: "100%" }}>
            <input
              accept=".docx,.xlsx,.pdf,.xls,.png,.bmp,.jpg,.jpeg,.doc"
              onChange={(e) => onFileChange(e)}
              type="file"
              hidden
            />
            <div className={classes.chooseFile}>Choose file</div>
          </label>
        </div>
        <div className="col-2">
          <button
            onClick={onAddDocument}
            disabled={!documentType || !selectedDocumentFile}
            className={classes.addFile}
          >
            Add
          </button>
        </div>
      </div>
      <div className="mt-3">
        <TableContainer>
          <Table aria-label="simple table">
            {/* <TableHead>
          <TableRow>
            <TableCell>Dessert (100g serving)</TableCell>
            <TableCell align="right">Calories</TableCell>
            <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell>
          </TableRow>
        </TableHead> */}
            <TableBody>
              {documents.map((row, index) => (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {row?.documentType}
                  </TableCell>
                  <TableCell>
                    <a href={row?.url} target="_blank">
                      See document
                    </a>
                  </TableCell>
                  <TableCell>
                    <i
                      onClick={() => onDeleteDocument(index)}
                      className={`ri-delete-bin-line ${classes.delete}`}
                    ></i>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default OrderDocuments;
