import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@mui/styles";
import { Checkbox, Divider, FormControlLabel } from "@mui/material";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { toast } from "react-toastify";
import NumberFormatComponent from "../../../components/NumberFormatComponent";
import { RepaymentTermsType } from "./interfaces";
import { getImpersonateCount } from "../../../services/surplus-allocation";
const useStyles = makeStyles((theme) => ({
  hr: {
    opacity: 0.4,
  },
  mainText: {
    fontSize: 18,
  },

  boldText: {
    width: 200,
    fontWeight: 500,
  },

  summaryItemBox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  summaryItemGrandTotalBox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 20,
    marginBottom: 30,
  },
}));
const AllocationSummary = ({
  producesVatAmount,
  buyerOrSellerFee,
  feeOption,
  currencySymbol,
  rebateValue,
  grandTotal,
  control,
  addedProduces,
  setValue,
  watch,
  reset,
  errors,
  sellerCompany,
  totalEstimatedValue,
  setRepaymentTerms,
  repaymentTerms,
}: {
  producesVatAmount:number
  buyerOrSellerFee: string;
  feeOption: string;
  addedProduces: any[];
  rebateValue: any;
  currencySymbol: string;
  grandTotal: number;
  control: any;
  watch: any;
  errors: any;
  reset: any;
  setValue: any;
  sellerCompany?: { name: string; id: string };
  totalEstimatedValue: number;
  repaymentTerms?: RepaymentTermsType;
  setRepaymentTerms?: React.Dispatch<
    React.SetStateAction<RepaymentTermsType | undefined>
  >;
}) => {
  const classes = useStyles();

  const produceCurrency = watch("produceCurrency");
  const [loading, setLoading] = useState(false);
  const rebateOption = watch("rebateDiscount");
  const rebateOptionAmount = watch("rebateDiscountAmount");
  const watchFeePercentage = watch("feePercentage");
  const watchApplyInvoiceFactoring = watch("applyInvoiceFactoring");
  const isCreateInvoice = watch("isCreateInvoice");

  const [invoiceRequestAmount, setInvoiceRequestAmount] = useState(null);

  const receivableValue = useMemo(() => {
    return buyerOrSellerFee === "Buyer Fee" || !watchApplyInvoiceFactoring
      ? grandTotal
      : grandTotal - (parseFloat(watchFeePercentage) * grandTotal) / 100 || 0;
  }, [
    buyerOrSellerFee,
    grandTotal,
    watchFeePercentage,
    watchApplyInvoiceFactoring,
  ]);

  // const rebateValue =
  //   rebateOption?.name === "Percentage"
  //     ? (totalEstimatedValue * rebateOptionAmount) / 100
  //     : rebateOptionAmount;

  // const grandTotal = rebateValue
  //   ? totalEstimatedValue - rebateValue
  //   : totalEstimatedValue;

  useEffect(() => {
    if (sellerCompany?.id) {
      const fetchInvoiceRequestAmount = async () => {
        try {
          const response = await getImpersonateCount(
            sellerCompany?.id ? sellerCompany?.id : ""
          );
          setInvoiceRequestAmount(response);

          const pendingTotalValue = response?.pendingTotalValue;
        } catch (error) {
          toast.error("Something went wrong. Please try again later.");
        }
      };

      fetchInvoiceRequestAmount();
    }
  }, [sellerCompany?.id]);

  return (
    <div className="mt-1">
      <span className={classes.mainText}>Allocation Summary</span>
      <Divider className={classes.hr} />
      {loading && <LoadingIndicator loading={loading} />}
      <div style={{ width: "70%" }} className="d-flex flex-column pt-3">
        {/* <p className={classes.summaryItemBox}>
          <div className={classes.boldText}>Pending Total Value : </div>
          {
            <NumberFormat
              prefix={produceCurrency?.symbol}
              decimalScale={2}
              fixedDecimalScale
              displayType="text"
              thousandSeparator={true}
              value={invoiceRequestAmount?.pendingAmount || 0}
            />
          }
        </p> */}
        <p className={classes.summaryItemBox}>
          <div className={classes.boldText}>Estimated Total Value : </div>
          {
            <NumberFormatComponent
              prefix={currencySymbol}
              value={totalEstimatedValue}
            />
          }
        </p>
        <p className={classes.summaryItemBox}>
          <div className={classes.boldText}>Products VAT Value : </div>
          {
            <NumberFormatComponent
              prefix={currencySymbol}
              value={producesVatAmount}
            />
          }
        </p>

        <p className={classes.summaryItemBox}>
          <div className={classes.boldText}>Rebate/Discount Value : </div>
          {
            <NumberFormatComponent
              prefix={currencySymbol}
              value={rebateValue || 0}
            />
          }
        </p>
        <p className={classes.summaryItemGrandTotalBox}>
          <div className={classes.boldText}>Grand Total Value : </div>
          {
            <NumberFormatComponent
              prefix={currencySymbol}
              value={
                (rebateValue
                  ?producesVatAmount+ totalEstimatedValue - rebateValue
                  : producesVatAmount+ totalEstimatedValue) || 0
              }
            />
          }
        </p>
        <p className={classes.summaryItemBox}>
          <div className={classes.boldText}>Platform Fee : </div>
          {
            <NumberFormatComponent
              prefix={currencySymbol}
              value={
                (!watchApplyInvoiceFactoring
                  ? 0
                  : (grandTotal * parseFloat(watchFeePercentage)) / 100) || 0
              }
            />
          }
        </p>
        <p className={classes.summaryItemBox}>
          <div className={classes.boldText}>Receivable Value : </div>
          {
            <NumberFormatComponent
              prefix={currencySymbol}
              value={receivableValue}
            />
          }
        </p>
        <FormControlLabel
          style={{
            marginBottom: 15,
            display: "flex",
            flexDirection: "row",
            gap: 3,
          }}
          control={<Checkbox style={{ color: "#E77228", marginLeft: 9 }} />}
          label="Create invoice"
          checked={isCreateInvoice}
          value={isCreateInvoice}
          onChange={(e: any) => {
            setValue("isCreateInvoice", e.target.checked);
          }}
        />
      </div>
    </div>
  );
};

export default AllocationSummary;
