import { useCallback, useEffect, useMemo, useState } from "react";
import { OrderStateType } from "../../orderStateType";
import {
  addOrderDocuments,
  deleteOrderDocuments,
  getOrderDocuments,
  sendOrderDocuments,
} from "../../../../services/order-documents";
import AdminButtonComponent from "../../../../components/AdminButtonComponent";
import { makeStyles } from "@mui/styles";
import AdminModalComponent from "../../../../components/AdminModalComponent";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledComboBox from "../../../../components/ControlledComboBox";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import { Table } from "react-bootstrap";
import usePermissions from "../../../../hooks/usePermissions";
import PermissionWrapper from "../../../../components/PermissionWrapper";
import TagInput from "../../../../components/TagInput";

export interface DocumentsStateType {
  _id: string;
  documentName: string;
  documentPath: string;
  fileExt: string;
  uploadedBy: UploadedBy;
}

export interface UploadedBy {
  _id: string;
  fullName: string;
  id: string;
}

const documentNames = [
  "Shipping Document",
  "Picker Notes",
  "Credit Notes",
  "QC(Quality Control) report",
  "Invoice",
  "Purchase Order",
  "Sales Report",
  "Certificate of Origin",
  "Pesticides Record",
  "LOI (Letter of Indemnity)",
  "Packing List",
  "Logistics Booking Confirmation",
  "Logistics Forward Cargo Receipt",
  "Logistics BOL",
  "Logistics Airway Bill",
  "Others",
];

const schema = yup.object().shape({
  documentName: yup.string(),
  userType: yup.string(),
});

const useStyles = makeStyles({
  hr: {
    marginTop: 10,
    opacity: 0.07,
  },
  temporaryUrl: {
    color: "#000",
    "&:hover": {
      color: "#000",
    },
  },

  trash: {
    cursor: "pointer",
    fontSize: 20,
    color: "red",
  },
  imageTitle: {
    marginLeft: 5,
  },
  labelImage: {
    cursor: "pointer",
  },
  uploadSvg: {
    borderRadius: "100%",
    width: 50,
    height: 50,
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: 460,

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },

  headerTitle: {
    fontSize: 20,
  },
  newUserModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  saveButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  deleteButton: {
    width: "50%",
    height: "50px",
    background: "red!important",
    borderRadius: "20px !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  tableBox: {
    borderRadius: "20px",
    padding: 15,
    border: "1px solid #F6F6F6",
  },
  tableHeaderText: {
    color: "#737373",

    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "0.24px",
  },
  table: {
    borderBottom: "none!important",
  },
  tableColumnsText: {
    color: "#3D3D3D",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600!important",
    lineHeight: "normal",
    letterSpacing: "0.24px",
  },
  tableRowsText: {
    color: "#000",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    letterSpacing: "0.24px",
  },
  tableRowsHrefText: {
    color: "#E77228",
    "&:hover": {
      color: "#E77228!important",
    },
    cursor: "pointer",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    letterSpacing: "0.24px",
  },
  deleteIcon: {
    cursor: "pointer",
  },
  checkbox: {
    color: "#E77228!important",
  },
  addNew: {
    background: "#E77228!important",
    borderRadius: "10px!important",
  },
  sendDoc: {
    background: "#69CDAE!important",
    borderRadius: "10px!important",
  },
});

const OrderDocumentsBox = ({
  orderData,
}: {
  orderData: OrderStateType | undefined;
}) => {
  const { hasPermission } = usePermissions();
  const { watch, reset, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      documentName: "",
      userType: "",
    },
  });

  const watchDocumentName = watch("documentName");
  const watchUserType = watch("userType");

  const classes = useStyles();

  const [documents, setDocuments] = useState<DocumentsStateType[]>([]);
  const [refreshToken, setRefreshToken] = useState<number>(0);
  const [selectedCCEmails, setSelectedCCEmails] = useState<string[]>([
    "hello@frupro.com",
  ]);
  const [selectedTOEmails, setSelectedTOEmails] = useState<string[]>([
    "operations@finmid.com",
  ]);

  const [selectedDocs, setSelectedDocs] = useState<string[]>([])
  const [isShowInOrder, setIsShowInOrder] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>("");
  const [openAddDocumentModal, setOpenAddDocumentModal] =
    useState<boolean>(false);
  const [openSendDocumentsModal, setOpenSendDocumentsModal] =
    useState<boolean>(false);
  const [openDeleteDocumentModal, setOpenDeleteDocumentModal] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const tradabaseFiles = useMemo(() => {
    return orderData?.tradabaseFiles.length
      ? orderData?.tradabaseFiles?.map((item) => ({
        documentName: item.fileName,
        _id: "",
        documentPath: item.url,
        fileExt: item.ext,
        uploadedBy: {
          _id: "",
          fullName: "",
          id: "",
        },
      }))
      : [];
  }, [orderData?.tradabaseFiles?.length]);

  const aiFiles = useMemo(() => {
    return orderData?.documentaiFiles?.length
      ? orderData?.documentaiFiles?.map((ai) => ({
        documentName: "Invoice",
        _id: "",
        documentPath: ai.fileUrl,
        fileExt: ai?.fileName?.split(".")?.[1],
        uploadedBy: {
          _id: "",
          fullName: "",
          id: "",
        },
      }))
      : [];
  }, [orderData?.documentaiFiles?.length]);

  const newDocuments = useMemo(() => {
    return documents.concat(aiFiles as any, tradabaseFiles as any);
  }, [aiFiles?.length, tradabaseFiles?.length, documents?.length, orderData]);

  const onSelectFile = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };

  const onOpenAddDocumentModal = () => {
    setOpenAddDocumentModal(true);
  };
  const onOpenSendDocumentsModal = () => {
    setOpenSendDocumentsModal(true);
  };
  const onOpenDeleteDocumentModal = (id: string) => {
    setOpenDeleteDocumentModal(true);
    setSelectedDocumentId(id);
  };
  const onCloseAddDocumentModal = () => {
    setOpenAddDocumentModal(false);
  };
  const onCloseSendDocumentsModal = () => {
    setOpenSendDocumentsModal(false);
  };
  const onCloseDeleteDocumentModal = () => {
    setOpenDeleteDocumentModal(false);
  };

  useEffect(() => {
    if (orderData?._id) {
      const getDocuments = async () => {
        const response = await getOrderDocuments(orderData?._id);
        if (response) {
          setDocuments(response?.documents);
        }
      };
      getDocuments();
    }
  }, [orderData?._id, refreshToken]);

  const onAddDocument = async () => {
    if (!hasPermission("ORDERS_ORDER_DETAILS_EDIT")) return;

    setLoading(true);

    const finalData = {
      offerId: orderData?._id,
      visible: isShowInOrder,
      uploadedForUserId:
        watchUserType === "Seller"
          ? orderData?.seller._id
          : orderData?.buyer._id,
      file: selectedFile,
      documentName: watchDocumentName,
      showMessage: true,
    };

    const added = await addOrderDocuments(finalData);
    setLoading(false);

    if (added) {
      setSelectedFile(undefined);
      reset({});
      setIsShowInOrder(false);
      setOpenAddDocumentModal(false);
      setRefreshToken(() => refreshToken + 1);
    }
  };

  const onDeleteDocument = async () => {
    if (!hasPermission("ORDERS_ORDER_DETAILS_EDIT")) return;

    setLoading(true);

    const deleted = await deleteOrderDocuments(
      orderData?._id ? orderData?._id : "",
      selectedDocumentId
    );
    setLoading(false);

    if (deleted) {
      setOpenDeleteDocumentModal(false);
      setRefreshToken(() => refreshToken + 1);
    }
  };

  const onSendDocument = async () => {
    setLoading(true);
    const finalData = {
      documentIds:selectedDocs,
      to: selectedTOEmails,
      cc: selectedCCEmails,
      offerId: orderData?._id,
    };
    const response = await sendOrderDocuments(finalData);
    setLoading(false);

    if (response) {
      setSelectedDocs([])
      setOpenSendDocumentsModal(false);
      setSelectedTOEmails(["mark@finmid.com", "charlotte@finmid.com"]);
      setSelectedCCEmails(["commercial@frupro.com"]);
    }
  };

  const onChangeDocsSelectChange = useCallback(
    (id: string) => {
      if (selectedDocs.includes(id)) {
        setSelectedDocs((prev) => prev.filter((p) => p !== id));
      } else {
        setSelectedDocs((prev) => [...prev, id]);
      }
    },
    [selectedDocs]
  );

  const renderModalBody = () => {
    return (
      <>
        <div className="row g-3 ">
          <div className="d-flex flex-row gap-2 align-items-center">
            <label className={classes.labelImage}>
              {selectedFile ? (
                <>
                  <a
                    className={classes.temporaryUrl}
                    target="_blank"
                    href={URL.createObjectURL(selectedFile)}
                    rel="noreferrer"
                  >
                    {selectedFile?.name}
                  </a>
                </>
              ) : (
                <img
                  className={classes.uploadSvg}
                  src="/static/svg/upload.svg"
                />
              )}
              <span className={classes.imageTitle}>
                {selectedFile ? null : "Please select a file to upload"}
              </span>
              <input
                onChange={(e) => onSelectFile(e)}
                type="file"
                accept=".xlsx,.pdf,.doc,.docx,.jpg,.jpeg,.bmp,.png"
                hidden
              />
            </label>
            {selectedFile ? (
              <i
                onClick={() => setSelectedFile(undefined)}
                className={`ri-delete-bin-line ${classes.trash} `}
              ></i>
            ) : null}
          </div>

          <ControlledComboBox
            className={classes.comboBox}
            textLabel="Document Name"
            options={documentNames}
            control={control}
            name="documentName"
          />

          <ControlledComboBox
            className={classes.comboBox}
            textLabel="Please select which user owns this document"
            options={["Seller", "Buyer"]}
            control={control}
            name="userType"
          />

          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                onChange={(e) => setIsShowInOrder(e.target.checked)}
                checked={isShowInOrder}
              />
            }
            label="Show this document in order details"
          />
        </div>
        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseAddDocumentModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            disabled={!watchDocumentName || !selectedFile || !watchUserType}
            className={classes.saveButton}
            onClick={onAddDocument}
            title="Submit"
          />
        </Box>
      </>
    );
  };

  const renderSendModalBody = () => {
    return (
      <>
        <TagInput
          label="CC"
          placeholder="Press enter after typing"
          selected={selectedCCEmails}
          setSelected={setSelectedCCEmails}
        />
        <div className="mt-3">
          <TagInput
            label="TO"
            placeholder="Press enter after typing"
            selected={selectedTOEmails}
            setSelected={setSelectedTOEmails}
          />
        </div>
        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseSendDocumentsModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            disabled={!selectedCCEmails.length || !selectedTOEmails.length||!selectedDocs.length}
            className={classes.saveButton}
            onClick={onSendDocument}
            title="Send"
          />
        </Box>
      </>
    );
  };
  const renderDeleteModalBody = () => {
    return (
      <>
        <div className="row g-3">
          <span>Are you sure you want to delete this document?</span>
        </div>
        <Box className={classes.newUserModalButtons}>
          <AdminButtonComponent
            className={classes.closeButton}
            onClick={onCloseDeleteDocumentModal}
            title="Cancel"
          />{" "}
          <AdminButtonComponent
            disabled={!selectedDocumentId}
            className={classes.deleteButton}
            onClick={onDeleteDocument}
            title="Delete"
          />
        </Box>
      </>
    );
  };

  return (
    <div className="p-3">
      {loading && <LoadingIndicator loading={loading} />}
      <div className="d-flex justify-content-between align-items-center mb-2">
        <span className={classes.headerTitle}>Documents</span>
        <div className="d-flex flex-row gap-2">
          <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
            <AdminButtonComponent
              onClick={onOpenSendDocumentsModal}
              className={classes.sendDoc}
              title="Send documents"
            />
          </PermissionWrapper>
          <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
            <AdminButtonComponent
              onClick={onOpenAddDocumentModal}
              className={classes.addNew}
              title=" + Add new document"
            />
          </PermissionWrapper>
        </div>
        <AdminModalComponent
          children={renderModalBody()}
          openModal={openAddDocumentModal}
          closeModal={onCloseAddDocumentModal}
          headerTitle="Add new document"
        />
        <AdminModalComponent
          children={renderSendModalBody()}
          openModal={openSendDocumentsModal}
          closeModal={onCloseSendDocumentsModal}
          headerTitle="Please select documents to send"
        />
        <AdminModalComponent
          children={renderDeleteModalBody()}
          openModal={openDeleteDocumentModal}
          closeModal={onCloseDeleteDocumentModal}
          headerTitle="Are you sure"
        />
      </div>

      <hr className={classes.hr} />
      {newDocuments?.length ? (
        <div className="d-flex flex-column gap-2">
          <TableContainer className={classes.table}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.table}>

                  </TableCell>
                  <TableCell className={classes.table}>
                    <Typography className={classes.tableColumnsText}>
                      Document Name
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.table}>
                    <Typography className={classes.tableColumnsText}>
                      Document
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.table}>
                    <Typography className={classes.tableColumnsText}>
                      Uploader
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.table}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newDocuments?.map((doc, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {doc._id ? <TableCell
                      className={classes.table}
                      component="th"
                      scope="row"
                    >

                      <Checkbox
                      onChange={()=>onChangeDocsSelectChange(doc._id)}
                        checked={selectedDocs.includes(doc._id)}
                      />
                    </TableCell> : null}
                    <TableCell
                      className={classes.table}
                      component="th"
                      scope="row"
                    >
                      <Typography className={classes.tableRowsText}>
                        {" "}
                        {doc?.documentName}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.table}>
                      <a
                        target="_blank"
                        href={doc?.documentPath}
                        className={classes.tableRowsHrefText}
                        rel="noreferrer"
                      >
                        {" "}
                        {doc?.documentName}.{doc?.fileExt}
                      </a>
                    </TableCell>
                    <TableCell className={classes.table}>
                      {" "}
                      <Typography className={classes.tableRowsText}>
                        {" "}
                        {doc?.uploadedBy?.fullName}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.table}>
                      {doc?._id ? (
                        <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
                          <IconButton
                            onClick={() => onOpenDeleteDocumentModal(doc?._id)}
                          >
                            <i
                              onClick={() => setSelectedFile(undefined)}
                              className={`ri-delete-bin-line ${classes.trash} `}
                            ></i>
                          </IconButton>
                        </PermissionWrapper>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : null}
      {/* <hr className={classes.hr} />
      {orderData?.tradabaseFiles.length ? (
        <div className="d-flex flex-column gap-2">
          <TableContainer className={classes.table}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.table}>
                    <Typography className={classes.tableColumnsText}>
                      Document Name
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.table}>
                    <Typography className={classes.tableColumnsText}>
                      Document
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.table}>
                    <Typography className={classes.tableColumnsText}>
                      Uploader
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.table}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderData.tradabaseFiles?.map((doc, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      className={classes.table}
                      component="th"
                      scope="row"
                    >
                      <Typography className={classes.tableRowsText}>
                        {" "}
                        {doc?.fileName}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.table}>
                      <a
                        target="_blank"
                        href={doc?.url}
                        className={classes.tableRowsHrefText}
                        rel="noreferrer"
                      >
                        {" "}
                        {doc?.fileName}.{doc.ext}
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : null} */}
    </div>
  );
};

export default OrderDocumentsBox;
