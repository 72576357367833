import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";


export const getProduceTotalVats= async (data:any) => {
  try {
    const response = await axiosApiInstance.post(
      `/trade-produces/vats`,{...data}
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};


export const getSellerFees = async (page: number) => {
  try {
    const response = await axiosApiInstance.get(
      `/finmid/get-sellerfees?page=${page}`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getSellerFeesWithoutPagination = async (companyId: string) => {
  try {
    const response = await axiosApiInstance.post(`/fees/get-company-fees`, {
      companyId,
    });

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message || error?.message);
    }
  }
};
