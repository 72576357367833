import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { useParams } from "react-router-dom";
import { getCompanyById } from "../../services/company";
import { CompanyDetailsStateType } from "../CompanyList/CompanyDetails/interfaces";
import LoadingIndicator from "../../components/LoadingIndicator";
import ControlledTextField from "../../components/ControlledTextField";
import ControlledComboBox from "../../components/ControlledComboBox";
import { makeStyles } from "@mui/styles";
import CountryList from "../../country.json";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import {
  addNewSeller,
  checkBuyerSeller,
} from "../../services/verification-requests";
import AdminModalComponent from "../../components/AdminModalComponent";
import { useAppSelector } from "../../hooks/hooks";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

interface country {
  id: string;
  name: string;
}

export interface CheckIsExistsState {
  id: string;
  status: string;
  legal_name: string;
  legal_address: LegalAddress;
  business_registration_number: string;
  tax_number: string;
  email: string;
  phone: any;
}

export interface LegalAddress {
  address_line_1: string;
  postal_code: string;
  city: string;
  country: string;
  address_line_2: any;
  state: any;
}

const countries: country[] = CountryList.map((item: any) => ({
  id: item.code,
  name: item.countryName,
}));

const useStyles = makeStyles({
  mainBox: {
    marginTop: 10,
    padding: 20,
    width: "100%",
    background: "white",
    borderRadius: "20px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
  },
  headerTitle: {
    color: "#000",
    fontSize: 18,
  },
  hr: {
    opacity: 0.05,
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
});

const schema = yup.object().shape({
  BRN: yup.string().required("Business registration number is required"),
  taxNumber: yup.string().required("Tax number is required"),
  phoneNumber: yup.string().optional(),
  companyName: yup.string().required("Company name required"),
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Email is required"),
  fullAddress: yup.string().required("Address is required"),
  postalCode: yup.string().required("Postal code is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  country: yup.string().required("Country is required"),
  bankAccountType: yup.mixed().oneOf(["SWIFT", "SEPA", "FPS"]).required(),
  swift: yup.object().when("bankAccountType", {
    is: "SWIFT",
    then: () =>
      yup.object({
        accountNumberType: yup
          .string()
          .required("Account number type is required"),
        accountNumber: yup.string().required("Account number is required"),
        bic: yup.string().required("BIC is required"),
        address: yup.string().required("Address is required"),
        city: yup.string().required("City is required"),
        country: yup
          .object()
          .shape({
            id: yup.string().required("Country is required"),
            name: yup.string().required("Country is required"),
          })
          .required("Country is required"),
        currency: yup
          .object()
          .shape({
            id: yup.string().required("Currency is required"),
            name: yup.string().required("Currency is required"),
          })
          .required("Currency is required"),
      }),
    otherwise: () => yup.object().strip(),
  }),
  sepa: yup.object().when("bankAccountType", {
    is: "SEPA",
    then: () =>
      yup.object({
        iban: yup.string().required("IBAN is required"),
        bic: yup.string().required("BIC is required"),
        currency: yup
          .object()
          .shape({
            id: yup.string().required("Currency is required"),
            name: yup.string().required("Currency is required"),
          })
          .required("Currency is required"),
      }),
    otherwise: () => yup.object().strip(),
  }),
  fps: yup.object().when("bankAccountType", {
    is: "FPS",
    then: () =>
      yup.object({
        sortCode: yup.string().required("Sort code is required"),
        accountNumber: yup.string().required("Account number is required"),
        bankCode: yup.string().required("Bank code is required"),
        currency: yup
          .object()
          .shape({
            id: yup.string().required("Currency is required"),
            name: yup.string().required("Currency is required"),
          })
          .required("Currency is required"),
      }),
    otherwise: () => yup.object().strip(),
  }),
});

interface BankAccount {
  iban?: string;
  bic?: string;
  currency: string;
  sort_code?: string;
  account_number?: string;
  bank_code?: string;
  address?: string;
  city?: string;
  country?: string;
}

interface SellerData {
  companyId?: string;
  legal_name?: string;
  email?: string;
  phone?: string;
  legal_address: {
    address_line_1?: string;
    postal_code?: string;
    city?: string;
    country?: string;
  };
  bank_accounts: BankAccount[];
  business_registration_number?: string;
  tax_number?: string;
}

type FormData = yup.InferType<typeof schema>;

const AddFruPaySellerContainer = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      BRN: "",
      city: "",
      companyName: "",
      country: "",
      email: "",
      fullAddress: "",
      phoneNumber: "",
      postalCode: "",
      state: "",
      taxNumber: "",
      bankAccountType: "SWIFT" as const,
      swift: {
        accountNumberType: "",
        accountNumber: "",
        bic: "",
        address: "",
        city: "",
        country: undefined,
        currency: undefined,
      },
      sepa: undefined,
      fps: undefined,
    },
  });

  const currencies = useAppSelector((state) => state.auth.currencies);
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  const [checkLoading, setCheckLoading] = useState<boolean>(false);
  const [companyLoading, setCompanyLoading] = useState<boolean>(false);
  const countriesWithPhone = useAppSelector((state) => state.auth.countries);
  const [openIsExistsModal, setOpenIsExistsModal] = useState<boolean>(false);
  const [isInFinmidData, setIsInFinmidData] = useState<CheckIsExistsState>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [company, setCompany] = useState<CompanyDetailsStateType>();

  const bankAccountType = watch("bankAccountType");

  const defaultCountryInput = countries.find(
    (item) => item.name === company?.principalPlaceCountry.name
  );

  const defaultPhoneCode = countriesWithPhone.find((item) =>
    company?.userDetails.phoneNumber.includes(`+${item.phone}`)
  )?.phone;

  const getErrorMessage = (obj: any, path: string) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj)
      ?.message;
  };

  useEffect(() => {
    if (
      isInFinmidData?.status === "PENDING" ||
      isInFinmidData?.status === "ACTIVE" ||
      isInFinmidData?.status === "INACTIVE" ||
      isInFinmidData?.status === "REJECTED" ||
      isInFinmidData?.status === "SUSPENDED"
    ) {
      setOpenIsExistsModal(true);
    }
  }, [isInFinmidData?.status]);

  //check seller is exists on FinMid

  useEffect(() => {
    const checkExists = async () => {
      setCheckLoading(true);
      const data = {
        companyId: id,
        companyType: "sellers",
      };
      const checked = await checkBuyerSeller(data);
      setCheckLoading(false);

      if (checked) {
        setIsInFinmidData(checked?.data);
      }
    };
    checkExists();
  }, []);

  useEffect(() => {
    if (id) {
      const getCompanyData = async () => {
        setCompanyLoading(true);
        const response = await getCompanyById(id);

        if (response) {
          setCompany(response);
        }
        setCompanyLoading(false);
      };
      getCompanyData();
    }
  }, [id]);

  useEffect(() => {
    if (company?.id) {
      setSelectedCountry(defaultPhoneCode || "");
      reset({
        country: defaultCountryInput?.id || "",
        companyName: company?.companyName || "",
        state: company.principalPlaceState?.name || "",
        city: company.principalPlaceCity?.name || "",
        BRN: company?.VATNumber,
        //IBAN: company?.ibanNumber,
        //BIC: company?.swiftCode,
        bankAccountType: "SWIFT",
        taxNumber: company?.companyHouseRegistration,
        postalCode: company?.principalPlacePostCode,
        fullAddress: company?.principalPlaceAddress,
        email: company.userDetails?.email,
        phoneNumber: company.userDetails?.phoneNumber.split(
          defaultPhoneCode || ""
        )[1],
      });
    }
  }, [company?.id]);

  const selectionCurrencies = currencies.map((item) => ({
    id: item.name,
    name: item.code,
  }));

  const onAddSeller = async (data: any) => {
    setLoading(true);

    const bankAccount: any = (() => {
      switch (data.bankAccountType) {
        case "SEPA":
          return {
            iban: data.sepa.iban,
            bic: data.sepa.bic,
            currency: data.sepa.currency.name,
          };
        case "FPS":
          return {
            sort_code: data.fps.sortCode,
            account_number: data.fps.accountNumber,
            bank_code: data.fps.bankCode,
            currency: data.fps.currency.name,
          };
        case "SWIFT":
          return {
            account_number_type:
              data?.swift?.accountNumberType === "Account Number"
                ? "ACCOUNT_NUMBER"
                : data?.swift?.accountNumberType,
            account_number: data.swift.accountNumber,
            bic_swift: data.swift.bic,
            address: {
              address_line: data.swift.address,
              city: data.swift.city,
              country: CountryList.find(
                (c) => c.countryName === data?.swift?.country?.name
              )?.code,
            },
            currency: data.swift.currency.name,
          };
        default:
          return {} as BankAccount;
      }
    })();

    // Remove undefined properties
    Object.keys(bankAccount).forEach((key: string) => {
      if (bankAccount[key as keyof BankAccount] === undefined) {
        delete bankAccount[key as keyof BankAccount];
      }
    });

    const finalData: SellerData = {
      companyId: id,
      legal_name: company?.companyName,
      email: data?.email,
      phone:
        selectedCountry && data?.phoneNumber
          ? `+${selectedCountry}${data?.phoneNumber}`
          : undefined,
      legal_address: {
        address_line_1: data?.fullAddress,
        postal_code: data?.postalCode,
        city: data?.city,
        country: data?.country,
      },
      bank_accounts: [bankAccount],
      business_registration_number: data?.BRN,
      tax_number: data?.taxNumber,
    };

    // Remove undefined properties from the final data
    Object.keys(finalData).forEach((key: string) => {
      if (finalData[key as keyof SellerData] === undefined) {
        delete finalData[key as keyof SellerData];
      }
    });

    setLoading(false);
    const added = await addNewSeller(finalData);

    if (added) {
      navigate("/company-list");
    }
  };

  const onCloseExistsModal = () => {
    setOpenIsExistsModal(false);
    navigate("/company-list");
  };

  const renderBankAccountFields = () => {
    switch (bankAccountType) {
      case "SWIFT":
        return (
          <>
            <div className="row pb-2 g-4">
              <div className="col-md-4">
                <ControlledComboBox
                  className={classes.comboBox}
                  options={["IBAN", "Account Number"]}
                  textLabel="Account Number Type"
                  control={control}
                  name="swift.accountNumberType"
                  error={!!getErrorMessage(errors, "swift.accountNumberType")}
                  helperText={getErrorMessage(
                    errors,
                    "swift.accountNumberType"
                  )}
                />
              </div>
              <div className="col-md-4">
                <ControlledTextField
                  textLabel="IBAN/Account Number"
                  control={control}
                  name="swift.accountNumber"
                  error={!!getErrorMessage(errors, "swift.accountNumber")}
                  helperText={getErrorMessage(errors, "swift.accountNumber")}
                />
              </div>
              <div className="col-md-4">
                <ControlledTextField
                  textLabel="BIC"
                  control={control}
                  name="swift.bic"
                  error={!!getErrorMessage(errors, "swift.bic")}
                  helperText={getErrorMessage(errors, "swift.bic")}
                />
              </div>
              <div className="col-md-4">
                <ControlledTextField
                  textLabel="Address"
                  control={control}
                  name="swift.address"
                  error={!!getErrorMessage(errors, "swift.address")}
                  helperText={getErrorMessage(errors, "swift.address")}
                />
              </div>
              <div className="col-md-4">
                <ControlledComboBox
                  className={classes.comboBox}
                  options={countries}
                  textLabel="Country"
                  control={control}
                  name="swift.country"
                  error={!!getErrorMessage(errors, "swift.country.id")}
                  helperText={getErrorMessage(errors, "swift.country.id")}
                />
              </div>
              <div className="col-md-4">
                <ControlledTextField
                  textLabel="City"
                  control={control}
                  name="swift.city"
                  error={!!getErrorMessage(errors, "swift.city")}
                  helperText={getErrorMessage(errors, "swift.city")}
                />
              </div>
              <div className="col-md-4">
                <ControlledComboBox
                  className={classes.comboBox}
                  options={selectionCurrencies}
                  textLabel="Currency"
                  control={control}
                  name="swift.currency"
                  error={!!getErrorMessage(errors, "swift.currency.id")}
                  helperText={getErrorMessage(errors, "swift.currency.id")}
                />
              </div>
            </div>
          </>
        );
      case "SEPA":
        return (
          <>
            <div className="row pb-2 g-4">
              <div className="col-md-4">
                <ControlledTextField
                  textLabel="IBAN"
                  control={control}
                  name="sepa.iban"
                  error={!!getErrorMessage(errors, "sepa.iban")}
                  helperText={getErrorMessage(errors, "sepa.iban")}
                />
              </div>
              <div className="col-md-4">
                <ControlledTextField
                  textLabel="BIC"
                  control={control}
                  name="sepa.bic"
                  error={!!getErrorMessage(errors, "sepa.bic")}
                  helperText={getErrorMessage(errors, "sepa.bic")}
                />
              </div>
              <div className="col-md-4">
                <ControlledComboBox
                  className={classes.comboBox}
                  control={control}
                  name="sepa.currency"
                  error={!!getErrorMessage(errors, "sepa.currency")}
                  helperText={getErrorMessage(errors, "sepa.currency")}
                  options={selectionCurrencies}
                  textLabel={"Currency"}
                />
              </div>
            </div>
          </>
        );
      case "FPS":
        return (
          <>
            <div className="row pb-2 g-4">
              <div className="col-md-4">
                <ControlledTextField
                  textLabel="Sort Code"
                  control={control}
                  name="fps.sortCode"
                  error={!!getErrorMessage(errors, "fps.sortCode")}
                  helperText={getErrorMessage(errors, "fps.sortCode")}
                />
              </div>
              <div className="col-md-4">
                <ControlledTextField
                  textLabel="Account Number"
                  control={control}
                  name="fps.accountNumber"
                  error={!!getErrorMessage(errors, "fps.accountNumber")}
                  helperText={getErrorMessage(errors, "fps.accountNumber")}
                />
              </div>
              <div className="col-md-4">
                <ControlledTextField
                  textLabel="Bank Number"
                  control={control}
                  name="fps.bankCode"
                  error={!!getErrorMessage(errors, "fps.bankCode")}
                  helperText={getErrorMessage(errors, "fps.bankCode")}
                />
              </div>
              <div className="col-md-4">
                <ControlledComboBox
                  className={classes.comboBox}
                  options={selectionCurrencies}
                  textLabel="Currency"
                  control={control}
                  name="fps.currency"
                  error={!!getErrorMessage(errors, "fps.currency")}
                  helperText={getErrorMessage(errors, "fps.currency")}
                />
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const renderModalBody = () => {
    return (
      <>
        <span className="">Company is already exists as seller on FinMid.</span>
        <div className="d-flex justify-content-end  mt-3">
          <AdminButtonComponent
            disabled={loading}
            className={classes.closeButton}
            onClick={onCloseExistsModal}
            title="Close"
          />{" "}
        </div>
      </>
    );
  };
  return (
    <>
      <Sidebar
        renderButton={
          isInFinmidData?.status === "PENDING" ||
          isInFinmidData?.status === "ACTIVE" ||
          isInFinmidData?.status === "INACTIVE" ||
          isInFinmidData?.status === "REJECTED" ||
          isInFinmidData?.status === "SUSPENDED" ? null : (
            <div className="">
              <AdminButtonComponent
                title="Submit"
                onClick={handleSubmit(onAddSeller)}
              />
            </div>
          )
        }
        pageTitle="New Seller"
      >
        {loading && <LoadingIndicator loading={loading} />}

        {checkLoading || companyLoading ? (
          <Skeleton count={1} style={{ height: 600 }} />
        ) : isInFinmidData?.status === "PENDING" ||
          isInFinmidData?.status === "ACTIVE" ||
          isInFinmidData?.status === "INACTIVE" ||
          isInFinmidData?.status === "REJECTED" ||
          isInFinmidData?.status === "SUSPENDED" ? null : (
          <div className={classes.mainBox}>
            <div>
              <p className={classes.headerTitle}>Seller Information</p>
              <hr className={classes.hr} />
              <div className="row pb-2 g-4">
                <div className="col-md-4">
                  <ControlledTextField
                    textLabel="Company Name"
                    control={control}
                    name="companyName"
                    error={!!errors?.companyName}
                    helperText={errors?.companyName?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <ControlledTextField
                    textLabel="Email"
                    control={control}
                    name="email"
                    error={!!errors?.email}
                    helperText={errors?.email?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <PhoneNumberInput
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    control={control}
                    name="phoneNumber"
                    error={!!errors?.phoneNumber}
                    helperText={errors?.phoneNumber?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <ControlledTextField
                    textLabel="Business Registration Number(BRN)"
                    control={control}
                    name="BRN"
                    error={!!errors?.BRN}
                    helperText={errors?.BRN?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <ControlledTextField
                    textLabel="Tax Number"
                    control={control}
                    name="taxNumber"
                    error={!!errors?.taxNumber}
                    helperText={errors?.taxNumber?.message as string}
                  />
                </div>
              </div>
            </div>
            <div className="mt-3">
              <p className={classes.headerTitle}>Legal Address</p>
              <hr className={classes.hr} />
              <div className="row pb-2 g-4">
                <div className="col-md-4">
                  {company?.id && (
                    <ControlledComboBox
                      className={classes.comboBox}
                      defaultValue={defaultCountryInput}
                      options={countries}
                      textLabel="Country"
                      control={control}
                      name="country"
                      error={!!errors?.country}
                      helperText={errors?.country?.message as string}
                    />
                  )}
                </div>
                <div className="col-md-4">
                  <ControlledTextField
                    textLabel="State"
                    control={control}
                    name="state"
                    error={!!errors?.state}
                    helperText={errors?.state?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <ControlledTextField
                    textLabel="City"
                    control={control}
                    name="city"
                    error={!!errors?.city}
                    helperText={errors?.city?.message as string}
                  />
                </div>
                <div className="col-md-4">
                  <ControlledTextField
                    textLabel="Postal Code"
                    control={control}
                    name="postalCode"
                    error={!!errors?.postalCode}
                    helperText={errors?.postalCode?.message as string}
                  />
                </div>
                <div className="col-md-12">
                  <ControlledTextField
                    textLabel="Address"
                    control={control}
                    name="fullAddress"
                    error={!!errors?.fullAddress}
                    helperText={errors?.fullAddress?.message as string}
                  />
                </div>
              </div>
            </div>
            <div className="mt-3">
              <p className={classes.headerTitle}>Bank Account Info</p>
              <hr className={classes.hr} />
              <RadioGroup
                className="mt-2"
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                style={{ display: "flex", flexDirection: "row", gap: 20 }}
              >
                {["SWIFT", "SEPA", "FPS"].map((item, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      defaultChecked
                      checked={bankAccountType === item}
                      value={item}
                      control={<Radio style={{ color: "#E77228" }} />}
                      label={item}
                      onChange={() => {
                        reset({
                          ...watch(),
                          bankAccountType: item,
                          swift: {
                            accountNumberType: "",
                            accountNumber: "",
                            bic: "",
                            address: "",
                            city: "",
                            country: undefined,
                            currency: undefined,
                          },
                          sepa: {
                            iban: "",
                            bic: "",
                            currency: undefined,
                          },
                          fps: {
                            sortCode: "",
                            accountNumber: "",
                            bankCode: "",
                            currency: undefined,
                          },
                        });
                      }}
                    />
                  );
                })}
              </RadioGroup>
              {renderBankAccountFields()}
            </div>
          </div>
        )}
      </Sidebar>
      <AdminModalComponent
        children={renderModalBody()}
        openModal={openIsExistsModal}
        closeModal={() => setOpenIsExistsModal(false)}
      />
    </>
  );
};

export default AddFruPaySellerContainer;
