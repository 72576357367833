import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";

export const getOrderDocuments = async (offerId: string) => {
  try {
    const response = await axiosApiInstance.get(
      `/documents/admin/get-offer-documents/${offerId}`
    );
    if (response) return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const deleteOrderDocuments = async (
  offerId: string,
  documentId: string
) => {
  try {
    const response = await axiosApiInstance.delete(
      `/documents/admin/delete-offer-documents/${offerId}/${documentId}`
    );
    if (response) {
      toast.success("Document is deleted successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const addOrderDocuments = async (data: any) => {
  try {
    const formData = new FormData();

    formData.append("documentName", data?.documentName);
    formData.append("visible",data?.visible)
    formData.append("file", data?.file);
  

    const response = await axiosApiInstance.post(
      `/documents/admin/upload-offer-document/${data?.offerId}/${data?.uploadedForUserId}`,

      formData,

      {
        headers: {
          "Content-type": "multipart/form-data",
        },
      }
    );
    if (response) {
    data?.showMessage&&  toast.success("File uploaded successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};


export const sendOrderDocuments = async (data:any) => {
  try {
    const response = await axiosApiInstance.post(
      `/trade-documents/send`,{...data}
    );
    if (response) {
      toast.success("Documents send successfully.")
      return response.data
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
