import { axiosApiInstance } from "./axios-instance";
import axios from "axios";
import { toast } from "react-toastify";
import { ImagesEntity1 } from "../pages/SubProductTypeContainer/components/SubProductType";

export const getVarieties = async (page: number) => {
  try {
    const response = await axiosApiInstance.get(
      `/trade-variety?page=${page}&&limit=${100}`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const addVariety = async (name: string) => {
  try {
    const response = await axiosApiInstance.post(`/trade-info-add`, {
      type: "VARIETY",
      data: {
        name: name,
      },
    });

    if (response) {
      toast.success("Variety added successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const editVariety = async (name: string, docId: string) => {
  try {
    const response = await axiosApiInstance.post(`/trade-info-update`, {
      type: "VARIETY",
      data: {
        name: name,
        id: docId,
      },
    });

    if (response) {
      toast.success("Variety edited successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
export const getSizes = async (page: number) => {
  try {
    const response = await axiosApiInstance.get(
      `/trade-size?page=${page}&&limit=${100}`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const addSize = async (name: string) => {
  try {
    const response = await axiosApiInstance.post(`/trade-info-add`, {
      type: "SIZE",
      data: {
        name: name,
      },
    });

    if (response) {
      toast.success("Size added successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const editSize = async (name: string, docId: string) => {
  try {
    const response = await axiosApiInstance.post(`/trade-info-update`, {
      type: "SIZE",
      data: {
        name: name,
        id: docId,
      },
    });

    if (response) {
      toast.success("Size edited successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getUnits = async (page: number, unitId: string) => {
  const filterObj = {
    subProductID: unitId,
  };
  try {
    const qp = filterObj
      ? new URLSearchParams(
          filterObj && Object.entries(filterObj).filter(([key, value]) => value)
        ).toString()
      : "";

    const response = await axiosApiInstance.get(
      `/trade-unit?page=${page}&&limit=${100}&${qp}`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const addUnit = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(`/trade-info-add`, {
      type: "UNIT",
      ...data,
    });

    if (response) {
      toast.success("Unit added successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const editUnit = async (data: any) => {
  try {
    const response = await axiosApiInstance.post(`/trade-info-update`, {
      type: "UNIT",
      ...data,
    });

    if (response) {
      toast.success("Unit edited successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const addSubproductType = async (
  productTypeId: string,
  name: string,
  description?: string
) => {
  try {
    const response = await axiosApiInstance.post(
      `/product-types/${productTypeId}/sub-product-types`,
      {
        name,
        description: description ?? "",
      }
    );

    if (response) {
      toast.success("Subproduct Type added successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const addSubproductTypeImages = async (
  subProductTypeId: string,
  image: any
) => {
  try {
    const response = await axiosApiInstance.put(
      `/sub-product-types/${subProductTypeId}/images`,
      {
        images: [image],
      }
    );

    if (response) {
      toast.success("Subproduct type image added successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const editSubproductType = async (
  productTypeId: string,
  subProductTypeId: string,
  name: string,
  description?: string
) => {
  try {
    const response = await axiosApiInstance.put(
      `/product-types/${productTypeId}/sub-product-types/${subProductTypeId}`,
      {
        name,
        description: description ?? "",
      }
    );

    if (response) {
      toast.success("Subproduct Type edited successfully.");
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};

export const getImageUrl = async (image: ImagesEntity1) => {
  try {
    let img = {
      name: image.name,
      path: image.path,
      size: image.size,
      thumbPath: image.thumbPath,
    };

    const response = await axiosApiInstance.post(`get-url`, {
      photo: img,
    });

    if (response) {
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data.message);
    }
  }
};
